import { useState } from "react";

export const useEmarsysEvents = () => {
  const [reportSent, setReportSent] = useState(false);
  const [alreadyPushedEvents, setAlreadyPushedEvents] = useState<any[]>([]);

  const prepareEventData = (event: any) => {
    switch (event.eventType) {
      case "cart":
        return prepareCartEventData(event.eventData);
      case "view":
        return prepareViewEventData(event.eventData);
      case "purchase":
        return preparePurchaseEventData(event.eventData);
      case "category":
        return prepareCategoryEventData(event.eventData);
    }
  };

  const prepareCartEventData = (eventData: any) => {
    if (!eventData || alreadyPushedEvents.includes("cart")) {
      return;
    }

    const cartTrackingData: any = [];
    eventData.basket.entries?.forEach((entry: any) => {
      cartTrackingData.push({
        item: entry.product?.code,
        price: entry.basePrice?.value,
        quantity: entry.quantity,
      });
    });

    setAlreadyPushedEvents([...alreadyPushedEvents, "cart"]);
    return cartTrackingData;
  };

  const prepareViewEventData = (productId: any) => {
    if (!productId || alreadyPushedEvents.includes("view")) {
      return;
    }

    setAlreadyPushedEvents([...alreadyPushedEvents, "view"]);
    return productId;
  };

  const preparePurchaseEventData = (order: any) => {
    if (!order || alreadyPushedEvents.includes("purchase")) {
      return;
    }

    const orderItems: any = [];
    order.entries?.forEach((entry: any) => {
      orderItems.push({
        item: entry.product?.code,
        price: entry.basePrice?.value,
        quantity: entry.quantity,
      });
    });

    setAlreadyPushedEvents([...alreadyPushedEvents, "purchase"]);
    return {
      orderId: order.code,
      items: orderItems,
    };
  };

  const prepareCategoryEventData = (category: any) => {
    if (!category || alreadyPushedEvents.includes("category")) {
      return;
    }

    const categoryPath: any[] = [];
    category.categoryBreadcrumb.map((cat: any) => {
      categoryPath.push(cat.name);
    });

    setAlreadyPushedEvents([...alreadyPushedEvents, "purchase"]);
    return categoryPath.join(">");
  };

  return (events: any) => {
    if (!events.length || reportSent) return;

    const scarabQueue = window.ScarabQueue;
    if (!scarabQueue) return;
    events.forEach((event: any) => {
      const eventReportData = prepareEventData(event);
      scarabQueue.push([event.eventType, eventReportData]);
    });

    scarabQueue.push(["go"]);
    setReportSent(true);
  };
};
